import { BsTriangleFill } from "react-icons/bs";
import {
  FaBars,
  FaCheck,
  FaChevronRight,
  FaCloudUploadAlt,
  FaCopy,
  FaEllipsisH,
  FaEye,
  FaEyeSlash,
  FaGripLines,
  FaMinusCircle,
  FaPause,
  FaPen,
  FaPlay,
  FaPlus,
  FaRegEnvelope,
  FaRegUser,
  FaRegUserCircle,
  FaShareAlt,
  FaStop,
  FaTrashAlt,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeOff,
  FaVolumeUp,
} from "react-icons/fa";
import { FcConferenceCall, FcHeadset, FcMultipleDevices, FcShare } from "react-icons/fc";
import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const UserOutlinedIcon = (props: IconProps) => <Icon as={FaRegUser} {...props} />;
export const UserCircleOutlinedIcon = (props: IconProps) => <Icon as={FaRegUserCircle} {...props} />;
export const EnvelopeOutlinedIcon = (props: IconProps) => <Icon as={FaRegEnvelope} {...props} />;
export const PlusIcon = (props: IconProps) => <Icon as={FaPlus} {...props} />;
export const VisibleIcon = (props: IconProps) => <Icon as={FaEye} {...props} />;
export const HiddenIcon = (props: IconProps) => <Icon as={FaEyeSlash} {...props} />;
export const CheckIcon = (props: IconProps) => <Icon as={FaCheck} {...props} />;
export const EllipsisIcon = (props: IconProps) => <Icon as={FaEllipsisH} {...props} />;
export const ShareIcon = (props: IconProps) => <Icon as={FaShareAlt} {...props} />;
export const PenIcon = (props: IconProps) => <Icon as={FaPen} {...props} />;
export const RemoveIcon = (props: IconProps) => <Icon as={FaMinusCircle} {...props} />;
export const TrashIcon = (props: IconProps) => <Icon as={FaTrashAlt} {...props} />;
export const CopyIcon = (props: IconProps) => <Icon as={FaCopy} {...props} />;
export const CloudUploadIcon = (props: IconProps) => <Icon as={FaCloudUploadAlt} {...props} />;
export const DragHandleIcon = (props: IconProps) => <Icon as={FaGripLines} {...props} />;
export const MenuIcon = (props: IconProps) => <Icon as={FaBars} {...props} />;
export const PlayIcon = (props: IconProps) => <Icon as={FaPlay} {...props} />;
export const PauseIcon = (props: IconProps) => <Icon as={FaPause} {...props} />;
export const StopIcon = (props: IconProps) => <Icon as={FaStop} {...props} />;
export const VolumeHighIcon = (props: IconProps) => <Icon as={FaVolumeUp} {...props} />;
export const VolumeLowIcon = (props: IconProps) => <Icon as={FaVolumeDown} {...props} />;
export const VolumeOffIcon = (props: IconProps) => <Icon as={FaVolumeOff} {...props} />;
export const VolumeMuteIcon = (props: IconProps) => <Icon as={FaVolumeMute} {...props} />;
export const ChevronRightIcon = (props: IconProps) => <Icon as={FaChevronRight} {...props} />;
export const UpsideDownTriangleIcon = (props: IconProps) => (
  <Icon as={BsTriangleFill} transform={"rotate(180deg)"} {...props} />
);

export const HeadsetColorIcon = (props: IconProps) => <Icon as={FcHeadset} {...props} />;
export const MultiDeviceColorIcon = (props: IconProps) => <Icon as={FcMultipleDevices} {...props} />;
export const ShareColorIcon = (props: IconProps) => <Icon as={FcShare} {...props} />;
export const UsersColorIcon = (props: IconProps) => <Icon as={FcConferenceCall} {...props} />;
