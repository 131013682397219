// https://help.amplitude.com/hc/en-us/articles/115000465251-Data-Taxonomy-Playbook
//
// Naming: lowercase, present-tense verb + object

import { config } from "@lib/utils/config";

export type AmplitudePropertyType = boolean | string | string[] | number | number[] | null;

export type AmplitudeEvent = {
  event: string;
  data?: { [key: string]: AmplitudePropertyType };
};

export type IncrementalUserProperties = "songs" | "playlists" | "songViews" | "songLoads" | "songsShared" | "playlistsShared";

export type UserProperties = "purposes" | "invited" | "origin";

const isDemo = (songId: string) => songId === config.demoSongId;

export const events = {
  index: {
    loadPage: { event: "index - load page" },
    clickRegister: { event: "index - click register" },
    clickSignIn: { event: "index - click sign in" },
    clickDemo: { event: "index - click demo" },
  },

  all: {
    loadPage: { event: "all - load page" },
  },

  // TODO: track -> channel for events under song
  song: {
    loadPage: (data: { songId: string; secret: string | null }) => {
      return { event: "song - load page", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    loadTrack: (data: { trackId: string; songId: string; fromCache: boolean }) => {
      return { event: "song - load track", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    completeLoad: (data: { songId: string; songName: string; loadDurationSeconds: number }) => {
      return { event: "song - complete load", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    playSong: (data: {
      songId: string;
      songName: string;
      muteCount: number;
      soloCount: number;
      trackCount: number;
    }) => {
      return { event: "song - play song", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    pauseSong: (data: {
      songId: string;
      songName: string;
      muteCount: number;
      soloCount: number;
      trackCount: number;
    }) => {
      return { event: "song - pause song", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    stopSong: (data: {
      songId: string;
      songName: string;
      muteCount: number;
      soloCount: number;
      trackCount: number;
    }) => {
      return { event: "song - stop song", data: { ...data, isDemo: isDemo(data.songId) } };
    },
    viewCountRegistered: (data: { songId: string; minDurationSeconds: number }) => {
      return { event: "song - view count registered", data: { ...data, isDemo: isDemo(data.songId) } };
    },
  },

  playlist: {
    loadPage: (data: { playlistId: string; secret: string | null }) => {
      return { event: "playlist - load page", data };
    },
    renamePlaylist: (data: { playlistId: string; playlistName: string }) => {
      return { event: "playlist - rename playlist", data };
    },
    deletePlaylist: (data: { playlistId: string; playlistName: string }) => {
      return { event: "playlist - delete playlist", data };
    },
    togglePlaylistSharing: (data: { playlistId: string; playlistName: string; sharingEnabled: boolean }) => {
      return { event: "playlist - toggle playlist sharing", data };
    },
    resetPlaylistSharing: (data: { playlistId: string; playlistName: string }) => {
      return { event: "playlist - reset playlist sharing", data };
    },
  },

  library: {
    renameSong: (data: { songId: string; songName: string }) => {
      return { event: "library - rename song", data };
    },
    deleteSong: (data: { songId: string; songName: string }) => {
      return { event: "library - delete song", data };
    },
    toggleSongSharing: (data: { songId: string; songName: string; sharingEnabled: boolean }) => {
      return { event: "library - toggle song sharing", data };
    },
    resetSongSharing: (data: { songId: string; songName: string }) => {
      return { event: "library - reset song sharing", data };
    },
    addSongToPlaylist: (data: { songId: string; songName: string; playlistId: string; playlistName: string }) => {
      return { event: "library - add song to playlist", data };
    },
    removeSongFromPlaylist: (data: { songId: string; songName: string; playlistId: string; playlistName: string }) => {
      return { event: "library - remove song from playlist", data };
    },
  },

  upload: {
    selectTrack: (data: {
      fileName: string;
      fileSizeKb: number;
      fileType: string;
      trackDurationSeconds: number;
      channelCount: number;
      sampleRate: number;
    }) => {
      return { event: "upload - select track", data };
    },
    // renameTrack: (data: { trackLabel: string; fileName: string }) => {
    //   return { event: "upload - rename track", data };
    // },
    removeTrack: (data: { trackLabel: string; fileName: string }) => {
      return { event: "upload - remove track", data };
    },
    reorderTracks: { event: "upload - reorder tracks" },
    submit: (data: { songId: string; songName: string; trackCount: number, playlistId: string | null }) => {
      return { event: "upload - submit", data };
    },
    completeTranscode: (data: {
      songId: string;
      songName: string;
      transcodeDurationSeconds: number | null;
      compressionRatioPc: number[];
    }) => {
      return { event: "upload - complete transcode", data };
    },
    failTranscode: (data: { songId: string; songName: string; trackCount: number }) => {
      return { event: "upload - fail transcode", data };
    },
    completeUpload: (data: { songId: string; songName: string; uploadDurationSeconds: number }) => {
      return { event: "upload - complete upload", data };
    },
  },

  register: {
    loadPage: (data: { registrationsClosed: boolean; inviteCode: string | null }) => {
      return { event: "register - load page", data };
    },
    submit: (data: { organization: string | null; purposes: string[]; origin: string }) => {
      return { event: "register - submit", data };
    },
    confirm: { event: "register - confirm" },
    clickSignIn: { event: "register - click sign in" },
  },

  signIn: {
    clickRegister: { event: "sign in - click register" },
    clickForgotPassword: { event: "sign in - click forgot password" },
    clickUseMagicLink: { event: "sign in - click use magic link" },
    clickUsePassword: { event: "sign in - click use password" },
    signIn: (data: { method: "password" | "magic link" }) => {
      return { event: "sign in - sign in", data };
    },
    submitForgotPassword: { event: "sign in - submit forgot password" },
    submitUpdatedPassword: { event: "sign in - submit updated password" },
  },

  waitlist: {
    submit: { event: "waitlist - submit" },
  },

  account: {
    updateProfile: (data: Partial<{ organization: string | null; purposes: string[] }>) => {
      return { event: "account - update profile", data };
    },
    updateEmail: { event: "account - update email" },
    updatePassword: { event: "account - update password" },
  },

  support: {
    clickDocumentation: { event: "support - click documentation" },
    clickSupportEmail: { event: "support - click support email" },
    openTicket: { event: "support - open ticket" },
  },

  sidebar: {
    clickCreatePlaylist: { event: "sidebar - click create playlist" },
    createPlaylist: (data: { playlistId: string; playlistName: string }) => {
      return { event: "sidebar - create playlist", data };
    },
    clickPlaylist: (data: { playlistId: string; playlistName: string }) => {
      return { event: "sidebar - click playlist", data };
    },
    clickUploadSong: { event: "sidebar - click upload song" },
    clickAllSongs: { event: "sidebar - click all songs" },
  },

  navbar: {
    clickHeading: { event: "navbar - click heading" },
    clickUser: { event: "navbar - click user" },
    clickAccount: { event: "navbar - click account" },
    clickSupport: { event: "navbar - click support" },
    clickDonate: { event: "navbar - click donate" },
    clickSignOut: { event: "navbar - click sign out" },
    clickSignIn: { event: "navbar - click sign in" },
    clickRegister: { event: "navbar - click register" },
    clickPlaylist: (data: { playlistId: string; playlistName: string }) => {
      return { event: "navbar - click playlist", data };
    },
  },

  general: {
    redirectToSignIn: { event: "general - redirect to sign in" },
  },

  banner: {
    clickFacebookGroupLink: { event: "banner - click facebook group link" },
  },

  error: {
    loadPage: (data: { statusCode: number }) => {
      return { event: "error - load page", data };
    },
    clickReturn: { event: "error - click return" },
    clickSupport: { event: "error - click support" },
  },
};
